@import "../vars.css";

.header {
  display: flex;
  flex-flow: row;
  background-color: var(--header-footer-background);
  align-items: center;
  padding: 5px;
  justify-content: space-between;
  margin: 0 0 10px 0;
  width: calc(100% - 10px);
}

.header p {
  font-size: 1rem;
  color: var(--color-black);
}

.button {
  padding: 10px;
  width: 100px;
  background-color: var(--link-colour);
  border: 1px solid var(--link-colour);
  font-size: 1rem;
}

.note {
  color: var(--link-colour);
  font-weight: bold;
  font-size: 1.2rem;
}
