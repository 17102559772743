@import "../vars.css";

.footer {
  padding: 5px;
  margin: 10px 0 0 0;
  background: var(--header-footer-background);
  width: calc(100% - 10px);
  font-size: 1rem;
}
.link {
  color: var(--link-colour);
}
