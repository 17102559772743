@import "./vars.css";

.app {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--app-background);
}

.title {
  font-size: 2rem;
  color: var(--color-black);
}
.wrapper {
  border: 1px dashed var(--color-black);
  padding: 10px;
}
