@import "../vars.css";

.row {
  display: flex;
  border-bottom: 1px solid var(--color-black);
  max-width: 302px;
}
.row:last-child {
  border-bottom: none;
}
