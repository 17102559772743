@import "../vars.css";

.cell {
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  text-transform: uppercase;
}
.cell:nth-child(2) {
  border-left: 1px solid var(--color-black);
}
.cell:last-child {
  border-left: 1px solid var(--color-black);
}
.cell:hover {
  background-color: var(--cell-hover);
}

.winner,
.winner:hover {
  background-color: var(--winner-cell-background);
  color: var(--link-colour);
}
